import http from "./http";

// Export all services
export const messagesService = {
  getIssueTypeList,
  createSupportTicket,
  getTicketsData,
  getTicketsDetails,
  markTicketAsRead
};

// Customer login services
function getIssueTypeList() {
  return http.get(`${process.env.REACT_APP_BASE_URL}/support/topics/`);
}
// Customer login services
function getTicketsData(payload: any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/support/tickets/?type=${payload}`);
}

// Customer login services
function createSupportTicket(payload: any) {
  
  if(payload.get("id")){
    let id = payload.get("id");
    payload.delete("id");
    payload.delete("topic");
    payload.delete("subject");
    return http.post(`${process.env.REACT_APP_BASE_URL}/support/reply/${id}`, payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }else {
    return http.post(`${process.env.REACT_APP_BASE_URL}/support/create-ticket/`, payload,
    {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
  
}

// Customer login services
function getTicketsDetails(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/support/tickets/${id}`,);
}

// Customer login services
function markTicketAsRead(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/support/read/${id}`,);
}
