import http from "./http";

export const financeService = {
  getFinanceData,
  getProposalListingService,
  getProposalDetailsService,
  getProposalTCService,
  getProposalItemsService,
  getProposalPaymentScheduleService,
  getSignNowLinkService,
  declineProposalService,
  changeOrderListService,
  viewProposalService
};

// material Selection Service
function getFinanceData(page: number) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/finance/invoices?page=${page}`
  );
}

// Get proposal listing Service
function getProposalListingService(payload: any) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/projects/${payload.projectId}/proposals?page=${payload.page}&perPage=${payload.perPage}`
  );
}

// Get proposal details service
function getProposalDetailsService(payload: any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}`);
}

// Get proposal terms and conditions service
function getProposalTCService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/get-terms/${id}`);
}

// Get proposal items service
function getProposalItemsService(payload: any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.proposalId}/items?sort=${payload.room}`);
}

// Get proposal payment schedule service
function getProposalPaymentScheduleService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/sales/proposal/get-payment-schedule/${id}`);
}

// Decline proposal service
function getSignNowLinkService(payload: any) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${payload.id}/link`, {
    role: payload.role
  });
}

// Decline proposal service
function declineProposalService(id: string) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/sales/proposal/${id}/decline`);
}

// change order list service
function changeOrderListService(payload: any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.projectId}/change-order?page=${payload.page}&perPage=${payload.perPage}`);
}

// view proposal
function viewProposalService(proposalId:any) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/proposal/${proposalId}`)
}