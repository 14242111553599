import { createAsyncThunk } from '@reduxjs/toolkit';
import { showHideLoader } from '../common/common.slice';
import {materialSelectionService} from '../../services/materialSelection.service'


// Material selection LIST
const materialSelectionAPI= createAsyncThunk(
    'materialSelectionSlice/materialSelectionAction', (payload : {projectId: string, hideLoader: boolean}, { dispatch, rejectWithValue }) => {
      if(!payload.hideLoader){
        dispatch(showHideLoader(true));
      }
       
      return materialSelectionService.matrialselectionNewService(payload)
        .then((response) => {
          if(!payload.hideLoader){
            dispatch(showHideLoader(false))
          }
          
          return response
        })
        .catch((error: { response: unknown; }) => {
          if(!payload.hideLoader){
            dispatch(showHideLoader(false))
          }
          return rejectWithValue(error?.response)
        })
    }
  );

// Material selection Versioning
const materialSelectionVersioning= createAsyncThunk(
  'materialSelectionVersionSlice/materialSelectionVersionAction', (payload : {index: number,materialId:string, type:string, groupItemIndex: number | undefined}, { dispatch, rejectWithValue }) => {
    //  dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionVersioningService(payload)
      .then((response) => {
        // dispatch(showHideLoader(false));
        return {response,versionIndex : payload.index, type: payload.type, groupItemIndex: payload?.groupItemIndex }
      })
      .catch((error: { response: unknown; }) => {
        // dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Material selection Accept change 
const materialSelectionAcceptChange= createAsyncThunk(
  'materialSelectionAcceptChnageSlice/materialSelectionAcceptChnageAction', (payload : {materialId: string,status:boolean,projectId: string}, { dispatch, rejectWithValue }) => {
    //  dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionAcceptChangeService(payload)
      .then((response) => {
        // dispatch(showHideLoader(false));
        // dispatch(materialSelectionAPI({projectId:payload.projectId, page: 1}))
        return response
      })
      .catch((error: { response: unknown; }) => {
        // dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Material selection accept all changes
const materialSelectionAcceptAllChangeAction= createAsyncThunk(
  'materialSelectionAcceptAllChangeSlice/materialSelectionAcceptAllChangeAction', (payload : {status: boolean, projectId: string; category_id:string; rooms:string[];}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionAcceptAllChangeService(payload)
      .then((response) => {
        dispatch(showHideLoader(false));
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Material selection Awaiting Sign Off list
const matrialselectionAwaitingSignOffListAction= createAsyncThunk(
  'matrialselectionAwaitingSignOffListAction/matrialselectionAwaitingSignOffListAction', (payload : {hideLoader:boolean, projectId: string,category_id:string,rooms:string[]}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionAwaitingSignOffListService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Save Material for Review
const materialSelectionReviewSave= createAsyncThunk(
  'materialSelectionReviewSave/materialSelectionReviewSave', (payload : {id: string, status: boolean}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionReviewSaveService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Save material for Review Action
const matrialSelectionReviewAllAction = createAsyncThunk(
  'matrialSelectionReviewAllAction/matrialSelectionReviewAllAction', (payload : {id: any, data: any}, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return materialSelectionService.matrialSelectionReviewAllService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Save Material for Review
const materialSelectionReviewList= createAsyncThunk(
  'materialSelectionReviewList/materialSelectionReviewList', (payload : {projectId: string, page: number, rooms: string[]}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionReviewListService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Get Sign Link
const matrialselectionGetSignLinkAction= createAsyncThunk(
  'matrialselectionGetSignLinkAction/matrialselectionGetSignLinkAction', (payload : {projectId: string, redirectURL: string}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionGetSignService(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);


// Material selection Approved list
const matrialselectionApproveList= createAsyncThunk(
  'matrialselectionApproveList/matrialselectionApproveList', (payload : {projectId: string,page: number, category_id: string, rooms: string[]}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.matrialselectionApproveList(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Material selection Approved list
const materialSelectionVerifyDocumentID= createAsyncThunk(
  'materialSelectionVerifyDocumentID/materialSelectionVerifyDocumentID', (payload: {projectId: string, documentId: string}, { dispatch, rejectWithValue }) => {
     dispatch(showHideLoader(true));
    return materialSelectionService.materialSelectionVerifyDocumentID(payload)
      .then((response) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

  export const materialSelectionActions = {
    materialSelectionAPI,
    materialSelectionVersioning,
    materialSelectionAcceptChange,
    materialSelectionAcceptAllChangeAction,
    matrialselectionAwaitingSignOffListAction,
    materialSelectionReviewSave,
    matrialSelectionReviewAllAction,
    materialSelectionReviewList,
    matrialselectionApproveList,
    matrialselectionGetSignLinkAction,
    materialSelectionVerifyDocumentID
  }
