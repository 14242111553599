import http from "./http";

export const galleryService = {
  galleryListService,
  downloadMultipleImages,
  deleteImage,
  galleryUploadService
};

// List Directories
function galleryListService(
  projectId: string,
  payload?: any
) {
  let roomsFilter =  queryStringParams(payload);
  // encoding query parameter
  let category = encodeURIComponent(payload?.rooms[0]);
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/gallery?page=${payload.page}&perPage=16${roomsFilter.length ? `&category=${category}`:""}`
  );
}

function downloadMultipleImages(
  imageIds: string[],
){
  return http.post(`gallery/generate-zip`, {
    ids: imageIds
  })
}

function deleteImage(
  imageId: string
){
  return http.delete(`gallery/${imageId}`);
}


function queryStringParams(data:any) {
  let filters = "";
  
  if (data?.hasOwnProperty("rooms")) {
      const companyArray = [...data.rooms];
      for (let i = 0; i < companyArray.length; i++) {
        if(companyArray[i] !== undefined) {
          filters += "&roomId[]=" + companyArray[i];
          data.hasOwnProperty("companyArray") && delete data["companyArray"];
        }
      }
      delete data.companyArray
  }
  return filters;
}

// Document Uploads
function galleryUploadService(projectId: string, formData: {}) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL}/projects/${projectId}/gallery/upload`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}