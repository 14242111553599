import { createAsyncThunk } from "@reduxjs/toolkit";
import { showHideLoader } from "../common/common.slice";
import { galleryService } from "../../services/gallery.service";
import { setOnFileUploadFail } from "./gallery.slice";

// upload Gallery Action
const fileUploadAction = createAsyncThunk(
  "gallerySlice/fileUploadAction",
  (payload: any, { dispatch, rejectWithValue }) => {
    const { projectId, formData } = payload;
    dispatch(showHideLoader(true));
    dispatch(setOnFileUploadFail(false));
    return galleryService
      .galleryUploadService(projectId, formData)
      .then((response) => {
        dispatch(showHideLoader(false));
        dispatch(setOnFileUploadFail(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        dispatch(setOnFileUploadFail(true));
        return rejectWithValue(error?.response);
      });
  }
);


export const galleryActions = {
  fileUploadAction,
};
