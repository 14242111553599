import { createSlice } from "@reduxjs/toolkit";
import { materialSelectionActions } from "./materialSelection.actions";

interface initialStateTypes {
  materialListData: any;
  materialListAwaitingData:any;
  error: string;
  loading: boolean;
  status: "idle" | "loading" | "failed";
  isVersionLoading: boolean;
  isAccepted: boolean;
  isAcceptloading: boolean;
  savedCount: number;
  isSaved: boolean;
  savedList: any;
  approvedList:any;
  signLink: string;
  signLoading: boolean;
  signedMaterialData: any;
}

const initialState: initialStateTypes = {
  materialListData: null,
  materialListAwaitingData:null,
  error: "",
  loading: false,
  status: "loading",
  isVersionLoading: false,
  isAccepted: false,
  isAcceptloading: false,
  savedCount: 0,
  isSaved: false,
  savedList: null,
  approvedList:null,
  signLoading: false,
  signLink: "",
  signedMaterialData: null
};

// ------ Material Selection Overview Reducer for managing  overview state. ------
const materialSelectionSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of Material New Items api data -------
      .addCase(
        materialSelectionActions.materialSelectionAPI.pending,
        (state, action) => {
          state.error = "";
          // can not reset this as the previous state is required in the next listing page
          // state.materialListData = null;
          // if(action.meta.arg.page === 1){
            state.materialListData = null;
          // }
          state.loading = true;
          state.isAccepted = false;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionAPI.fulfilled,
        (state, action) => {
          if (action.payload.data?.pagination?.page > 1 && state?.materialListData) {
            const prevData = JSON.parse(
              JSON.stringify(
                state.materialListData?.data?.data ? state.materialListData?.data?.data: [])
            );
            const newData = action?.payload?.data?.data?.data;
            const pagination = action?.payload?.data?.pagination;

            const final = [...prevData, ...newData];
            state.materialListData.data.data = final;
            state.materialListData.pagination = pagination;
          } else {
            state.materialListData = action.payload.data;
          }
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionAPI.rejected,
        (state, action) => {
          state.loading = false;
          state.materialListData = {};
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Selection Versioning api call #######################
      .addCase(
        materialSelectionActions.materialSelectionVersioning.pending,
        (state, action) => {
          state.error = "";
          state.isVersionLoading = true;
          state.materialListAwaitingData = null;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionVersioning.fulfilled,
        (state, action) => {
          const {response, versionIndex, type, groupItemIndex}= action.payload;
          if(type === "New"){
            state.materialListData.data.data[versionIndex] =  response?.data?.data
          }else if(groupItemIndex !== undefined){
            let tempData = {...response?.data?.data, showDetails: true}
            state.materialListAwaitingData.data[groupItemIndex].details[versionIndex] =  tempData;
          }
          
          state.isVersionLoading = false;
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionVersioning.rejected,
        (state, action) => {
          state.isVersionLoading = false;
          state.materialListData = {};
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Selection accept changes api call #######################
      .addCase(
        materialSelectionActions.materialSelectionAcceptChange.pending,
        (state, action) => {
          state.error = "";
          state.isAcceptloading = true;
          state.isAccepted = false;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionAcceptChange.fulfilled,
        (state, action) => {
          state.isAccepted = action.payload?.data?.status === 200;
          state.isAcceptloading = false;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionAcceptChange.rejected,
        (state, action) => {
          state.loading = false;
          state.isAccepted = false;
          state.error = action?.error?.message || "";
          state.isAcceptloading = false;
        }
      )
      //  ################  material Selection accept chnages api call #######################
      .addCase(
        materialSelectionActions.matrialselectionAwaitingSignOffListAction.pending,
        (state, action) => {
          // if(action.meta.arg.page === 1){
          if(!action.meta.arg?.hideLoader){
            state.materialListAwaitingData = null; 
          }
          // }
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionAwaitingSignOffListAction.fulfilled,
        (state, action) => {
          if (action.payload.data?.pagination?.page > 1 && state.materialListAwaitingData) {
            // if(state?.materialListAwaitingData?.data){
              const prevData = JSON.parse(
                JSON.stringify(state.materialListAwaitingData?.data?.data? state.materialListAwaitingData?.data?.data: [])
              );
              const newData = action?.payload?.data?.data?.data;
              const pagination = action?.payload?.data?.pagination;

              const final = [...prevData, ...newData];
              state.materialListAwaitingData.data.data = final;
              state.materialListAwaitingData.pagination = pagination;
            // }
          } else {
            state.materialListAwaitingData = action.payload.data;
          }
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionAwaitingSignOffListAction.rejected,
        (state, action) => {
          state.loading = false;
          state.materialListAwaitingData = {};
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Selection Save api call #######################
      .addCase(
        materialSelectionActions.materialSelectionReviewSave.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
          state.isSaved = false;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionReviewSave.fulfilled,
        (state, action) => {
          state.savedCount=action.payload.data.data.count;
          state.isSaved=true;
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionReviewSave.rejected,
        (state, action) => {
          state.loading = false;
          state.isSaved = false;
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Selection Saved List call #######################
      .addCase(
        materialSelectionActions.materialSelectionReviewList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
          state.isAccepted = false;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionReviewList.fulfilled,
        
        (state, action) => {
          state.savedList=action?.payload?.data?.data;
          state.savedCount=action?.payload?.data?.data?.totalSelectedItems;
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionReviewList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Get Sign link call #######################
      .addCase(
        materialSelectionActions.matrialselectionGetSignLinkAction.pending,
        (state, action) => {
          state.error = "";
          state.signLoading = true;
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionGetSignLinkAction.fulfilled,
        (state, action) => {
          state.signLink=action.payload.data?.data?.url;
          state.error = "";
          state.signLoading = false;
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionGetSignLinkAction.rejected,
        (state, action) => {
          state.signLoading = false;
          state.error = action?.error?.message || "";
        }
      )
      //  ################  material Selection Approved List call #######################
      .addCase(
        materialSelectionActions.matrialselectionApproveList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
          state.approvedList = null;
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionApproveList.fulfilled,
        (state, action) => {
          if(action?.payload?.data?.status===200){
            // sheetItemsCount
            let sheetItemsCount = 0;
            action.payload.data.data.data.forEach((item: any) => {
              sheetItemsCount += item.details.length;
            });
            state.approvedList=action.payload.data;
            state.approvedList.sheetItemsCount = sheetItemsCount;
            state.error = "";
          }else{
            state.loading = false;
            state.error = action?.payload?.data?.message || "";
          }
        }
      )
      .addCase(
        materialSelectionActions.matrialselectionApproveList.rejected,
        (state, action:any) => {
          state.loading = false;
          state.error = action?.payload?.data?.message || "";
        }
      )
      //  ################  material Verify Document ID call #######################
      .addCase(
        materialSelectionActions.materialSelectionVerifyDocumentID.pending,
        (state, action) => {
          state.error = "";
          state.signedMaterialData = null;
          state.loading = true;
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionVerifyDocumentID.fulfilled,
        (state, action) => {
          state.signedMaterialData=action.payload.data;
          state.error = "";
        }
      )
      .addCase(
        materialSelectionActions.materialSelectionVerifyDocumentID.rejected,
        (state, action: any) => {
          state.loading = false;
          state.error = action?.error?.message || "";
          state.signedMaterialData = action?.payload?.data;
        }
      )
  },
});

export default materialSelectionSlice.reducer;
