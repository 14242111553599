import { createAsyncThunk } from "@reduxjs/toolkit";
import { messagesService } from "../../services/messages.services";
import { showHideLoader } from "../common/common.slice";

// Async Thunk Actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// Get Issues Static List Action
const getIssueTypeList = createAsyncThunk(
  "messagesSlice/getIssueTypeList",
  ({}: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return messagesService.getIssueTypeList().then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      });
  }
);
// Create Support Ticket Action
const createSupportTicket = createAsyncThunk(
  "messagesSlice/createSupportTicket",
  (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return messagesService.createSupportTicket(payload).then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      });
  }
);
// Create Support Ticket Action
const getTicketsData = createAsyncThunk(
  "messagesSlice/getTicketsData",
  (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return messagesService.getTicketsData(payload).then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      });
  }
);
// Get thread details
const getTicketsDetails = createAsyncThunk(
  "messagesSlice/getTicketsDetails",
  (id: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return messagesService.getTicketsDetails(id).then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      });
  }
);
// Get thread details
const markTicketAsRead = createAsyncThunk(
  "messagesSlice/markTicketAsRead",
  (id: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return messagesService.markTicketAsRead(id).then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      });
  }
);


export const messagesActions = {
  getIssueTypeList,
  createSupportTicket,
  getTicketsData,
  getTicketsDetails,
  markTicketAsRead
};
