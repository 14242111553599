import { createSlice } from "@reduxjs/toolkit";
import { galleryActions } from "./gallery.actions";

var moment = require("moment-timezone");
moment.tz.setDefault("America/New_York");
export interface userState {
  value: any;
  status: "idle" | "loading" | "failed";
}

// ------ Project Overview Reducer for managing Project overview state. ------
const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    error: "",
    reloadRecentFiles: false,
    fileUploadSuccess: false,
    onFileUploadFail: false,
    pageNo: 0,
    showModalForm: false,
    openModalForm: false,
  },
  reducers: {
    galleryResetReloadFiles: (state, action) => {
      state.reloadRecentFiles = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setShowModalForm: (state, action) => {
      state.showModalForm = action.payload;
    },
    setOpenModalForm: (state, action) => {
      state.openModalForm = action.payload;
    },
    setOnFileUploadFail: (state, action) => {
      state.onFileUploadFail = action.payload;
    },
    clearGalleryUploadErr: (state) => {
      state.onFileUploadFail = false
    },
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of document upload files api data -------
      .addCase(galleryActions.fileUploadAction.pending, (state) => {
        state.error = "";
        state.reloadRecentFiles = false;
      })
      .addCase(galleryActions.fileUploadAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
          state.reloadRecentFiles = true;
          state.fileUploadSuccess = action.payload.data.status;
          state.pageNo = 1;
        } else {
          state.error = "";
          state.reloadRecentFiles = false;
          state.fileUploadSuccess = false;
          state.pageNo = 1;
        }
      })
      .addCase(galleryActions.fileUploadAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Unable to upload images";
        state.reloadRecentFiles = false;
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;

export default gallerySlice.reducer;
export const {
  galleryResetReloadFiles,
  setPageNo,
  setOnFileUploadFail,
  setShowModalForm,
  setOpenModalForm,
  clearGalleryUploadErr
} = gallerySlice.actions;
