import http from "./http";

export const materialSelectionService = {
  matrialselectionNewService,
  matrialselectionVersioningService,
  matrialselectionAcceptChangeService,
  matrialselectionAcceptAllChangeService,
  matrialselectionAwaitingSignOffListService,
  matrialselectionReviewSaveService,
  matrialSelectionReviewAllService,
  matrialselectionReviewListService,
  matrialselectionApproveList,
  matrialselectionGetSignService,
  materialSelectionVerifyDocumentID
};

// material Verify Document ID Service
function materialSelectionVerifyDocumentID(payload: {
  projectId: string;
  documentId: string;
}) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/sign-response?documentId=${payload.documentId}`
  );
}
// material Selection Service
function matrialselectionNewService(payload: {
  projectId: string;
}) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/list`
  );
}

// material Selection Versioning
function matrialselectionVersioningService(payload: { materialId: string }) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/material/${payload.materialId}/detail`
  );
}

// material Selection Versioning
function matrialselectionAcceptChangeService(payload: {
  materialId: string;
  status: boolean;
}) {
  return http.put(
    `${process.env.REACT_APP_BASE_URL}/material/${payload.materialId}/accept`,
    {
      status: payload.status,
    }
  );
}

// material selection accept all changes service
function matrialselectionAcceptAllChangeService(payload: {
    status: boolean;
    projectId: string;
    category_id: string;
    rooms: string[];
  }) {
    return http.put(
      `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/accept-all`,
      {
          status: payload?.status,
          sheetName: payload?.category_id,
          roomId: payload?.rooms
      }
    );
}

// setFilterState(filter.map((ele:any) =>{ return ele.value} ))
// material Selection Awaiting list off
function matrialselectionAwaitingSignOffListService(payload: {
    projectId: string;
    category_id:string;
    rooms:string[];
  }) {
     let roomsFilter =  queryStringParams(payload);
    return http.get(
        `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/sheet/?${roomsFilter.length ? `${roomsFilter}`:""}${payload.category_id.length ? `&sheetName=${payload.category_id}` : ""}`
    );
    
  }

// material Review Save
function matrialselectionReviewSaveService(payload: {
    id: string;
    status: boolean;
  }) {
    return http.put(
      `${process.env.REACT_APP_BASE_URL}/material/${payload.id}/reviewstatus`, {
        status: payload.status
      }
    );
  }

// material review all service
function matrialSelectionReviewAllService(payload: any) {
    return http.put(`${process.env.REACT_APP_BASE_URL}/material/${payload.id}/selectstatus-all`, {
      ...payload?.data
    }
    );
  }


// material Review List
function matrialselectionReviewListService(payload: {
  projectId: string;
  page: number;
  rooms: string[];
}) {
  let roomsFilter =  queryStringParams(payload);
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/review-summery?page=${payload.page}${roomsFilter.length ? `${roomsFilter}`:""}`
  );
}


function queryStringParams(data:any) {
    let filters = "";
    
    if (data.hasOwnProperty("rooms")) {
        const companyArray = [...data.rooms];
        for (let i = 0; i < companyArray.length; i++) {
            if(companyArray[i] !== undefined){
              filters += "&roomId[]=" + companyArray[i];
              data.hasOwnProperty("companyArray") && delete data["companyArray"];
            }
        }
        delete data.companyArray
    }
    return filters;
}


// Get material sign link service
function matrialselectionGetSignService(payload: {
    projectId: string;
    redirectURL:string;
  }) {
    
    return http.post(
      `${process.env.REACT_APP_BASE_URL}/material/sign-invite-link`,{
        ...payload
      }
    );
  }

// material Selection approved List
function matrialselectionApproveList(payload: {
    projectId: string;
    page:number;
    category_id:string;
    rooms: string[];
  }) {
    let roomsFilter =  queryStringParams(payload);
    return http.get(
      `${process.env.REACT_APP_BASE_URL}/material/${payload.projectId}/approved?page=${payload.page}&perPage=999${roomsFilter.length ? `${roomsFilter}`:""}${payload.category_id.length ? `&sheetName=${payload.category_id}` : ""}`
    );
  }

