import { createSlice } from "@reduxjs/toolkit";
import { messagesActions } from "./messages.actions";

var moment = require("moment-timezone");
moment.tz.setDefault("America/New_York");
export interface userState {
  value: any;
  status: "idle" | "loading" | "failed";
}

// ------ Project Overview Reducer for managing Project overview state. ------
const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    messagesTopic: null,
    error: "",
    isTicketCreated: null,
    createTicketLoader: false,
    ticketList: null,
    threadDetails: null,
    isLoading: false,
    isDetailsLoading: false
  },
  reducers: {
    resetReduxData: (state) => {
      state.isTicketCreated = null;
    },
    
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of document recent files api data -------
      .addCase(messagesActions.getIssueTypeList.pending, (state, action) => {
        state.error = "";
        state.messagesTopic = null;
      })
      .addCase(messagesActions.getIssueTypeList.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
          state.messagesTopic = action.payload.data;
        } else {
          state.error = "Unable to fetch message topics";
          state.messagesTopic = null;
        }
      })
      .addCase(messagesActions.getIssueTypeList.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Unable to fetch message topics";
        state.messagesTopic = null;
      })
      // -------- cases of Creating Ticket api data -------
      .addCase(messagesActions.createSupportTicket.pending, (state, action) => {
        state.error = "";
        state.isTicketCreated = null;
        state.createTicketLoader = true;
      })
      .addCase(messagesActions.createSupportTicket.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
          state.isTicketCreated = action.payload.data;
          state.createTicketLoader = false;
        } else {
          state.error = "Unable to assign ticket";
          state.isTicketCreated = null;
          state.createTicketLoader = false;
        }
        
      })
      .addCase(messagesActions.createSupportTicket.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Unable to assign ticket";
        state.isTicketCreated = null;
        state.createTicketLoader = false;
      })
      // -------- cases of get Tickets List api data -------
      .addCase(messagesActions.getTicketsData.pending, (state, action) => {
        state.error = "";
        state.ticketList = null;
        state.isLoading = true;
      })
      .addCase(messagesActions.getTicketsData.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
          state.ticketList = action.payload.data;
          state.isLoading = false;
        } else {
          state.error = "Unable to fetch tickets";
          state.ticketList = null;
          state.isLoading = false;
        }
        
      })
      .addCase(messagesActions.getTicketsData.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Unable to fetch tickets";
        state.ticketList = null;
        state.isLoading = false;
      })
      // -------- cases of get Tickets details api data -------
      .addCase(messagesActions.getTicketsDetails.pending, (state, action) => {
        state.error = "";
        state.threadDetails = null;
        state.isDetailsLoading = true;
      })
      .addCase(messagesActions.getTicketsDetails.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
          state.threadDetails = action.payload.data;
          state.isDetailsLoading = false;
        } else {
          state.error = "Unable to fetch ticket details";
          state.threadDetails = null;
          state.isDetailsLoading = false;
        }
      })
      .addCase(messagesActions.getTicketsDetails.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Unable to fetch ticket details";
        state.threadDetails = null;
        state.isDetailsLoading = true;
      })

  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const token = (state: RootState) => state.login.token;

export const {
  resetReduxData
} = messagesSlice.actions;

export default messagesSlice.reducer;

