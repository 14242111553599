import http from "./http";

export const documentService = {
  documentUploadService,
  documentRecentFilesService,
  documentListDirectoriesService,
  downloadLatestDocument,
  getSignNowUrl,
  verifyDocumentID
};

// Document Uploads
function documentUploadService(projectId: string, formData: {}) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL}/projects/${projectId}/documents/upload`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}

// Recent Files
function documentRecentFilesService(
  projectId: string,
  page: number = 1,
  perPage: number = 10,
  folderName: string = ""
) {
  return http.post(
    `${process.env.REACT_APP_BASE_URL}/documents/${projectId}/recent?page=${page}&perPage=${perPage}${folderName.length ? `&folderName=${folderName}` : ""}`,
    {}
  );
}

// List Directories
function documentListDirectoriesService(
  projectId: string,
  showAll: string = "1"
) {
  return http.get(
    `${process.env.REACT_APP_BASE_URL}/documents/${projectId}/list-directories?showAll=${showAll}`
  );
}

// Download latest document
function downloadLatestDocument(projectId: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/documents/${projectId}/latest-file`);
}

// get sign-now url for uploaded files
function getSignNowUrl(projectId: string, redirectionUrl: string, documentUrl: string|undefined) {
  return http.post(`${process.env.REACT_APP_BASE_URL}/signnow/sign-uploaded-document`, {
    projectId: projectId,
    redirectURL: redirectionUrl,
    docURL: documentUrl
  })
}

// verify signed document 
function verifyDocumentID(payload: any){
  if(payload?.type === 1){
    return http.get(
      `${process.env.REACT_APP_BASE_URL}/documents/sign-status?documentId=${payload.documentId}`
    );
  }else if(payload.type === 2){
    return http.get(
      `${process.env.REACT_APP_BASE_URL}/sales/proposal/sign-status?documentId=${payload.documentId}&documentType=${payload?.documentType}`
    );
  }else {
    return http.get(
      `${process.env.REACT_APP_BASE_URL}/documents/${payload.projectId}/sign-status?documentId=${payload.documentId}`
    );
  }
}
