import { createSlice } from "@reduxjs/toolkit";
import { financeActions } from "./finance.actions";

interface initialStateTypes {
  financeData: any;
  proposalListing: any;
  proposalDetails: any;
  proposalTC: any;
  proposalItems: any;
  proposalRoomItems: any;
  proposalPaymentSchedule: any;
  proposalDeclineData: any;
  changeOrderList:any;
  proposalInfo:any,
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  financeData: null,
  proposalListing: [],
  proposalDetails: null,
  proposalTC: null,
  proposalItems: null,
  proposalRoomItems:null,
  proposalPaymentSchedule: null,
  proposalDeclineData: null,
  changeOrderList: null,
  proposalInfo:null,
  error: "",
  loading: false
};

// ------ finance Overview Reducer for managing  overview state. ------
const financeSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of Material New Items api data -------
      .addCase(
        financeActions.getFinanceData.pending,
        (state, action) => {
          state.error = "";
          // state.materialListData = {};
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getFinanceData.fulfilled,
        (state, action) => {
          state.loading = false;
          if(action?.payload?.data?.status===200){
            state.financeData = action?.payload?.data;
            state.error = "";
          }else{
            state.financeData = null;
            state.error = action?.payload?.data?.message || "";
          }
        }
      )
      .addCase(
        financeActions.getFinanceData.rejected,
        (state, action:any) => {
          state.loading = false;
          state.financeData = null;
          state.error = action?.payload?.data?.message || "";
        }
      )

      // -------- cases of get proposal listing api data -------
      .addCase(
        financeActions.getProposalListingAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalListing = [];
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalListingAction.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.proposalListing = action.payload.data;
            state.error = "";
            state.loading = false;
          } else {
            state.proposalListing = [];
            state.error = "Unable to fetch proposal list"
          }
        }
      )
      .addCase(
        financeActions.getProposalListingAction.rejected,
        (state, action:any) => {
          state.loading = false;
          state.proposalListing = [];
          state.error = action?.payload?.data?.message || "Failed to fetch proposal list";
        }
      )

      // -------- cases of get proposal details api data -------
      .addCase(
        financeActions.getProposalDetailsAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalDetails = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalDetailsAction.fulfilled,
        (state, action) => {
          state.proposalDetails = action.payload.data.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        financeActions.getProposalDetailsAction.rejected,
        (state, action) => {
          state.loading = false;
          state.proposalDetails = null;
          state.error = action?.error?.message || "Failed to fetch proposal listing";
        }
      )

      // -------- cases of get proposal TC api data -------
      .addCase(
        financeActions.getProposalTCAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalTC = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalTCAction.fulfilled,
        (state, action) => {
          state.proposalTC = action.payload.data.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        financeActions.getProposalTCAction.rejected,
        (state, action) => {
          state.loading = false;
          state.proposalTC = null;
          state.error = action?.error?.message || "Failed to fetch proposal terms and condition";
        }
      )

      // -------- cases of get proposal items api data -------
      .addCase(
        financeActions.getProposalItemsAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalItems = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalItemsAction.fulfilled,
        (state, action) => {
          state.proposalItems = action.payload.data.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        financeActions.getProposalItemsAction.rejected,
        (state, action) => {
          state.loading = false;
          state.proposalItems = null;
          state.error = action?.error?.message || "Failed to fetch proposal items";
        }
      )

      // -------- cases of get proposal items api data -------
      .addCase(
        financeActions.getProposalRoomItemsAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalRoomItems = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalRoomItemsAction.fulfilled,
        (state, action) => {
          state.proposalRoomItems = action.payload.data.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        financeActions.getProposalRoomItemsAction.rejected,
        (state, action) => {
          state.loading = false;
          state.proposalRoomItems = null;
          state.error = action?.error?.message || "Failed to fetch proposal room items";
        }
      )

      // -------- cases of get proposal payment schedule api data -------
      .addCase(
        financeActions.getProposalPaymentScheduleAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalPaymentSchedule = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.getProposalPaymentScheduleAction.fulfilled,
        (state, action) => {
          state.proposalPaymentSchedule = action.payload.data.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        financeActions.getProposalPaymentScheduleAction.rejected,
        (state, action) => {
          state.loading = false;
          state.proposalPaymentSchedule = null;
          state.error = action?.error?.message || "Failed to fetch proposal payment schedule";
        }
      )

      // -------- cases of decline proposal api data -------
      .addCase(
        financeActions.declineProposalAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalDeclineData = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.declineProposalAction.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.proposalDeclineData = action.payload.data;
            state.error = "";
            state.loading = false;
          } else {
            state.proposalDeclineData = null;
            state.error = "Proposal decline failed";
            state.loading = false;
          }
        }
      )
      .addCase(
        financeActions.declineProposalAction.rejected,
        (state, action:any) => {
          state.loading = false;
          state.proposalDeclineData = null;
          state.error = action?.payload?.data?.message || "Failed to decline proposal";
        }
      )

      // -------- cases of change order list api data -------
      .addCase(
        financeActions.changeOrderListAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalDeclineData = null;
          state.loading = true;
        }
      )
      .addCase(
        financeActions.changeOrderListAction.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.changeOrderList = action.payload.data?.data;
            state.error = "";
            state.loading = false;
          } else {
            state.changeOrderList = null;
            state.error = "Failed to fetch change order list.";
            state.loading = false;
          }
        }
      )
      .addCase(
        financeActions.changeOrderListAction.rejected,
        (state, action:any) => {
          state.loading = false;
          state.changeOrderList = null;
          state.error = action?.payload?.data?.message || "Failed to fetch change order list.";
        }
      )

      // -------- cases of view proposal  -------
      .addCase(
        financeActions.viewProposalAction.pending,
        (state, action) => {
          state.error = "";
          state.proposalInfo = null;
          // need to update this for certain cases
          state.proposalDetails = null;

          state.loading = true;
        }
      )
      .addCase(
        financeActions.viewProposalAction.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.error = "";
            state.proposalInfo = action.payload.data.data;
            // need to update this for certain cases
          state.proposalDetails = action.payload.data.data;

            state.loading = false;
          } else {
            state.error = "Failed to view proposal.";
            state.proposalInfo = null;
            // need to update this for certain cases
            state.proposalDetails = null;

            state.loading = false;
          }
        }
      )
      .addCase(
        financeActions.viewProposalAction.rejected,
        (state, action:any) => {
          state.loading = false;
          state.proposalInfo = null;
          // need to update this for certain cases
          state.proposalDetails = null;
          
          state.error = action?.payload?.data?.message || "Failed to view proposal.";
        }
      )
  },
});

export default financeSlice.reducer;
