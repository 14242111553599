import { createAsyncThunk } from '@reduxjs/toolkit';
import { showHideLoader } from '../common/common.slice';
import {financeService} from '../../services/finance.service';


// Material selection LIST
const getFinanceData= createAsyncThunk(
  'materialSelectionSlice/getFinanceData', (page: number, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return financeService.getFinanceData(page)
      .then((response: any) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Proposal Listing
const getProposalListingAction= createAsyncThunk(
  'financeSlice/getProposalListingAction', (payload: any, { dispatch, rejectWithValue }) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalListingService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false))
        return response
      })
      .catch((error: { response: unknown; }) => {
        dispatch(showHideLoader(false))
        return rejectWithValue(error?.response)
      })
  }
);

// Proposal Details
const getProposalDetailsAction = createAsyncThunk(
  'financeSlice/getProposalDetailsAction', (payload:any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalDetailsService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Proposal Terms and condition
const getProposalTCAction = createAsyncThunk(
  'financeSlice/getProposalTCAction', (payload:any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalTCService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Proposal items
const getProposalItemsAction = createAsyncThunk(
  'financeSlice/getProposalItemsAction', (payload:any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalItemsService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Proposal room items
const getProposalRoomItemsAction = createAsyncThunk(
  'financeSlice/getProposalRoomItemsAction', (payload:any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalItemsService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Proposal items
const getProposalPaymentScheduleAction = createAsyncThunk(
  'financeSlice/getProposalPaymentScheduleAction', (payload:any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getProposalPaymentScheduleService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: { response: unknown }) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// get proposal sign-now redirection link
const getSignNowLinkAction = createAsyncThunk(
  'financeSlice/getSignNowLink', (payload: any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.getSignNowLinkService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: {response: any}) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Decline Proposal
const declineProposalAction = createAsyncThunk(
  'financeSlice/declineProposalAction', (payload: any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.declineProposalService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: {response: any}) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// Change Order list
const changeOrderListAction = createAsyncThunk(
  'financeSlice/changeOrderListAction', (payload: any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.changeOrderListService(payload)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: {response: any}) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

// view proposal action
const viewProposalAction = createAsyncThunk(
  'financeSlice/viewProposalction', (proposalId: any, {dispatch, rejectWithValue}) => {
    dispatch(showHideLoader(true));
    return financeService.viewProposalService(proposalId)
      .then((response: any) => {
        dispatch(showHideLoader(false));
        return response;
      })
      .catch((error: {response: any}) => {
        dispatch(showHideLoader(false));
        return rejectWithValue(error?.response);
      })
  }
)

export const financeActions = {
  getFinanceData,
  getProposalListingAction,
  getProposalDetailsAction,
  getProposalTCAction,
  getProposalItemsAction,
  getProposalPaymentScheduleAction,
  getSignNowLinkAction,
  declineProposalAction,
  getProposalRoomItemsAction,
  changeOrderListAction,
  viewProposalAction
}
