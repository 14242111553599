import http from "./http";

export const projectsService = {
  projectsTimeService,
  projectsPaymentService,
  projectsGalleryService,
  projectsPendingActionsService,
  updateDemoProdFlagsService
};

// Timeline Service
function projectsTimeService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/timeline/${id}`, );
}
// Payment Service
function projectsPaymentService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/payment-milestones/${id}`, );
}
// Gallery Service
function projectsGalleryService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/gallery-overview/${id}`, );
}
// Priority Actions Service
function projectsPendingActionsService(id: string) {
  return http.get(`${process.env.REACT_APP_BASE_URL}/projects/priority-actions/${id}`, );
}
// Project update Demo/Prod modal flags
function updateDemoProdFlagsService(payload: any) {
  return http.put(`${process.env.REACT_APP_BASE_URL}/projects/${payload?.id}/status`, {
    ...payload?.data
  });
}

 

